<template>
    <div class="page">
        <Part1 />
        <div class="mains">
            <div class="c-left">
                <div class="border"></div>
                <p class="fund">风险管理</p>
                <p class="txt">
                    风险模型作为风险管理的核心，能帮助管理者识别投资组合中的不同风险来源，控制风险暴露，理解组合中不同成分对总风险的贡献大小。风险模型帮助回答投资决策的过程中重要问题，例如我的长久期暴露如何影响总风险？我在金融行业的低配是否对冲了在银行板块的超配？我的股债基金组合是否满足在利率、信用利差、行业和风格因子的风险约束？
                </p>
                <p class="txt">
                    智君科技的多因子风险模型与资产定价模型借鉴了华尔街领先技术，涵盖股票、债券和公募基金等多种资产，应用在了组合构建、风险约束、风险分解、绩效归因、情景分析和压力测试与自动报告等诸多工具中。
                </p>
                <p class="solve">
                    <span>解决方案：</span>
                    <a><router-link to="/riskmeasurement">风险计量<svg-icon icon-class="圆箭头" style="padding:0 3px"></svg-icon></router-link></a>
                </p>
            </div>
            <div class="c-right">
                <img src="../../assets/customer/风险管理.png" alt="" style="width:474px;height:317px">
            </div>
        </div>
        <ApplyForm />
    </div>
    
</template>

<script>
import Part1 from './part1'
import ApplyForm from '../../components/ApplyForm'
export default {
    name:'RiskManage',
    components: { Part1,ApplyForm },
    data() {
        return {
            
        }
    }
}
</script>

<style lang="less" scoped>
.mains{
    width:1340px;
    height: 317px;
    margin:0 auto;
    // background:yellow;
    padding:79px 0 90px 0;
    .c-left{
        height:317px;
        // background:red;
        // width:15%;
        float:left;
        margin-left:5.4%;
        .border{
            width:130px;
            height:8px;
            background:#2E5082;
        }
        .fund{
            // width:308px;
            height:40px;
            font-size:32px;
            font-family:PingFangSC-Semibold,PingFang SC;
            font-weight:600;
            color:rgba(51,51,51,1);
            line-height:32px;
            margin:15px 0;
            text-align:left;
        }
        .txt{
            width:658px;
            // height:80px;
            // float:left;
            font-size:14px;
            font-family:PingFangSC-Regular,PingFang SC;
            font-weight:400;
            color:rgba(51,51,51,1);
            line-height:20px;
            text-indent: 28px;
            text-align:left;
            // margin-bottom:74px;
            
        }
        .mark{
                color:#2E5082;
                font-weight:bold;
            }
        .solve{
            // width:180px;
            height:20px;
            float:left;
            font-size:14px;
            font-family:PingFangSC-Regular,PingFang SC;
            // font-weight:400;
            color:rgba(51,51,51,1);
            line-height:20px;
            margin-top:19px;
            padding:0;
            a{
                text-decoration: none;
                color:#476CCB;
            }
        }

    }
    .c-right{
        width:474px;
        height:317px;
        float:right;
        margin-right:70px;
    }
    
}
</style>